import React from "react";
import {
  Select,
  MenuItem,
  Typography,
  TextField,
  InputBase,
  withStyles,
  FormControl,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import KenTextLabel from "../KenTextLabel";
import StatusErrorIcon from "./assets/StatusErrorIcon.svg";
import StatusSuccessIcon from "./assets/StatusSuccessIcon.svg";
import StatusWarningIcon from "./assets/StatusWarningIcon.svg";
import InputLabel from '@material-ui/core/InputLabel';


const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FAFBFC",
    border: "1px solid #ced4da",
    fontSize: 12,
    padding: '4px 26px 6px 10px',
    textAlign: "left",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: 2,
  },
  typoSupporting: {
    fontSize: "12px",
    // lineHeight: '16px',
    color: "theme.palette.KenColors.neutral100",
    //border: `1px solid ${theme.palette.KenColors.tertiaryYellow300}`,
  },
  inputBorderError: {
    border: `1px solid ${theme.palette.KenColors.tertiaryRed400}`,
    borderRadius: 3,
  },
  inputBorderSuccess: {
    border: `1px solid ${theme.palette.KenColors.tertiaryGreen300}`,
    borderRadius: 3,
  },
  inputBorder: {
    // border: '1px solid #DFE1E6',
    borderRadius: 3,
  },
}));

function getStyles(name, value, theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    whiteSpace: "normal",
  };
}

const KenSelect = function (props) {
  const classes = useStyles();
  const {
    id,
    options = [],
    value,
    onChange,
    label,
    name,
    placeholder,
    disabled,
    readonly,
    required,
    multiple,
    optionalLabel = true,
    errors,
    touched,
    onBlur,
    onFocus,
    autofocus,
    defaultValue,
  } = props;
  const theme = useTheme();

  const getErrorStatus = (type) => {
    let status, helpText;
    if (!touched) return;
    if (errors) {
      status = "error";
      helpText = (
        <span style={{ color: "#B92500" }}>
          <img src={StatusErrorIcon} alt="error"></img> {errors}
        </span>
      );
    } else if (errors) {
      status = "error";
      helpText = (
        <span style={{ color: "#B92500" }}>
          <img src={StatusErrorIcon} alt="error"></img> {errors}
        </span>
      );
    }

    if (touched && !errors) {
      status = "success";
      helpText = (
        <span style={{ color: "#006644" }}>
          {/* <img src={StatusSuccessIcon} alt='success'></img> Valid */}
        </span>
      );
    }

    if (type === "error") {
      return helpText;
    }
    if (type === "status") {
      switch (status) {
        case "warning":
          return "inputBorderWarning";
        case "success":
          return "inputBorderSuccess";

        case "error":
          return "inputBorderError";

        default:
          return "inputBorder";
      }
    }
  };

  return (
    <FormControl
      fullWidth
      disabled={disabled || readonly}
      className={classes.formControl}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <KenTextLabel
          label={label}
          required={required}
          optionalLabel={optionalLabel}
        />
        <Typography className={classes.typoSupporting}>
          {getErrorStatus("error")}
        </Typography>
      </div>
      <Select
        className={classes[getErrorStatus("status") || "inputBorder"]}
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled || readonly}
        fullWidth
        placeholder={placeholder}
        input={<BootstrapInput value={value} />}
        required={required}
        multiple={multiple}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autofocus}
        defaultValue={defaultValue}
      >
        {options.map((el) => (
          <MenuItem
            value={el.value || el}
            style={
              multiple
                ? getStyles(name, value, theme)
                : { whiteSpace: "normal" }
            }
          >
            {el.label || el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default KenSelect;
