import { createMuiTheme } from "@material-ui/core/styles";
import KenColors from './KenColors';
import { typography } from './base-theme';

export const overridings = {
  name: 'Light Theme',
  KenColors,
  shadows: ["none"],
  palette: {
    KenColors,
    primary: {
      light: KenColors.neutral700,
      main: KenColors.primary,
      dark: KenColors.neutral900,
      contrastText: "#fff"
    },
    secondary: {
      light: KenColors.shadowColor,
      main: KenColors.primary,
      dark: KenColors.secondaryPrime,
      contrastText: "#fff"
    }
  },
  typography: typography(KenColors)
};

export default createMuiTheme(overridings);