const primary = '#014b88';
// const primary = "#eb643a"; //hkbk primary
const background = "#F4F6FC";
const white = "#FFFFFF";
const black = "#000000";

// Secondary Colors
const secondaryPink1 = "#E6B3FF";
const secondaryPink2 = "#F5E1FF";
const secondaryPink3 = "#FAF0FF";

const secondaryPeach1 = "#FCA19C";
const secondaryPeach2 = "#FED9D7";
const secondaryPeach3 = "#FEECEB";

const secondaryYellow1 = "#FFE55B";
const secondaryYellow2 = "#FFF5BD";
const secondaryYellow3 = "#FFFADE";

const secondaryGreen1 = "#CEE75C";
const secondaryGreen2 = "#E3F496";
const secondaryGreen3 = "#F5FADE";

const secondaryPrime = "#9CD2FF";

//  Neutral Colors
const neutral900 = "#061938";
const neutral700 = "#253858";
const neutral400 = "#505F79";
const neutral100 = "#7A869A";
const neutral60 = "#B3BAC5";
const neutral40 = "#DFE1E6";
const neutral20 = "#F4F5F7";
const neutral10 = "#FAFBFC";

//newly added

const neutralGray10 = "#E5E5E5";
const neutralBlue10 = "#061956";

// Tertiary Colors
const tertiaryRed500 = "#B92500";
const tertiaryRed400 = "#DE350B";
const tertiaryRed300 = "#FF5630";
const tertiaryRed200 = "#FF7452";
const tertiaryRed100 = "#FF8F73";
const tertiaryRed75 = "#FFBDAD";
const tertiaryRed50 = "#FFEBE6";

const tertiaryYellow500 = "#FF8B00";
const tertiaryYellow400 = "#FF991F";
const tertiaryYellow300 = "#FFAB00";
const tertiaryYellow200 = "#FFC400";
const tertiaryYellow100 = "#FFE380";
const tertiaryYellow75 = "#FFF0B3";
const tertiaryYellow50 = "##FFFAE6";

const tertiaryGreen500 = "#006644";
const tertiaryGreen400 = "#00875A";
const tertiaryGreen300 = "#36B37E";
const tertiaryGreen200 = "#57D9A3";
const tertiaryGreen100 = "#79F2C0";
const tertiaryGreen75 = "#ABF5D1";
const tertiaryGreen50 = "#E3FCEF";

const tertiaryTeal500 = "#008DA6";
const tertiaryTeal400 = "#00A3BF";
const tertiaryTeal300 = "#00B8D9";
const tertiaryTeal200 = "#00C7E6";
const tertiaryTeal100 = "#79E2F2";
const tertiaryTeal75 = "#B3F5FF";
const tertiaryTeal50 = "#E6FCFF";

const tertiaryBlue500 = "#0747A6";
const tertiaryBlue400 = "#0052CC";
const tertiaryBlue300 = "#0065FF";
const tertiaryBlue200 = "#2684FF";
const tertiaryBlue100 = "#4C9AFF";
const tertiaryBlue75 = "#B3D4FF";
const tertiaryBlue50 = "#DEEBFF";

const tertiaryPurple500 = "#403294";
const tertiaryPurple400 = "#5243AA";
const tertiaryPurple300 = "#6554C0";
const tertiaryPurple200 = "#8777D9";
const tertiaryPurple100 = "#998DD9";
const tertiaryPurple75 = "#C0B6F2";
const tertiaryPurple50 = "#EAE6FF";

const shadowColor = "#D6E0FF";

const KenColors = {
  primary,
  background,
  white,
  black,
  secondaryPink1,
  secondaryPink2,
  secondaryPink3,
  secondaryPeach1,
  secondaryPeach2,
  secondaryPeach3,
  secondaryYellow1,
  secondaryYellow2,
  secondaryYellow3,
  secondaryGreen1,
  secondaryGreen2,
  secondaryGreen3,
  secondaryPrime,
  neutral900,
  neutral700,
  neutral400,
  neutral100,
  neutral60,
  neutral40,
  neutral20,
  neutral10,
  neutralBlue10,
  neutralGray10,
  tertiaryRed500,
  tertiaryRed400,
  tertiaryRed300,
  tertiaryRed200,
  tertiaryRed100,
  tertiaryRed75,
  tertiaryRed50,
  tertiaryYellow500,
  tertiaryYellow400,
  tertiaryYellow300,
  tertiaryYellow200,
  tertiaryYellow100,
  tertiaryYellow75,
  tertiaryYellow50,
  tertiaryGreen500,
  tertiaryGreen400,
  tertiaryGreen300,
  tertiaryGreen200,
  tertiaryGreen100,
  tertiaryGreen75,
  tertiaryGreen50,
  tertiaryTeal500,
  tertiaryTeal400,
  tertiaryTeal300,
  tertiaryTeal200,
  tertiaryTeal100,
  tertiaryTeal75,
  tertiaryTeal50,
  tertiaryBlue500,
  tertiaryBlue400,
  tertiaryBlue300,
  tertiaryBlue200,
  tertiaryBlue100,
  tertiaryBlue75,
  tertiaryBlue50,
  tertiaryPurple500,
  tertiaryPurple400,
  tertiaryPurple300,
  tertiaryPurple200,
  tertiaryPurple100,
  tertiaryPurple75,
  tertiaryPurple50,
  shadowColor,
};

export default KenColors;
