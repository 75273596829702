export const typography = (colors) => ({
    fontFamily: [
        'Open Sans',
        'sans-serif',
    ].join(','),
    body2: {
        fontSize: '12px',
        lineHeight: 1,
        color: colors.neutral400
    },
});