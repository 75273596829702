import React from "react";
import "./App.css";
import ThemeProvider from "./theme-provider";
import lightTheme from "./themes/light-theme";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./themes/styles.scss";
import Form from "./pages/Form";

function App(props) {
  return (
    <div className='App'>
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/'></Redirect>
            </Route>
            <Route exact path='/form' component={Form} />
            {/* <Route exact path="/applications" component={Applications} />
            <Route exact path="/form/:id" component={SideNav} />
            <Route exact path="/programs" component={AcademicPrograms} />
            <Route exact path="/intro/:id" component={Instruction} />
            <Route exact path="/pgForm" component={PgForm} />
            <Route exact path="/treeView" component={TreeView} />
            <Route exact path="/thankyou" component={ThankYouPage} /> */}
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
