import React, { useState, useEffect, createContext } from "react";
import { withFormik } from "formik";
import { Grid, makeStyles, Typography, Box, Radio } from "@material-ui/core";
import * as Yup from "yup";
import KenButton from "../../../components/KenButton";
import KenLoader from "../../../components/KenLoader/KenLoader";
import KenSnackBar from "../../../components/KenSnackbar";
import KenInputField from "../../../components/KenInputField";
import KenSelect from "../../../components/KenSelect";
import KenRadioGroup from "../../../components/KenRadioGroup";
import configContext from "../../../utils/config";
import { GLOBAL } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { otpGenerator } from "../../../utils/Otp";
import clsx from "clsx";
import {
  getYear,
  postDetails,
  getPrograms,
  getDepartment,
  createContact,
  getStatesNew,
  getCityNew,
  getTaluka,
  getvillage,
} from "../../../utils/ApiService";
import {
  sendOtpTo,
  isOtpValid,
  isUserAlreadyRegistered,
  getContactId,
  storeInfo,
} from "../../helper";
import KenCheckbox from "../../../components/KenCheckbox/index";
import CountryStateJson from "../../../utils/CountryStates.json";
import StateCityJson from "../../../utils/StateCity.json";
import CountryJson from "../../../utils/studentInfoCountry.json";
import Messages from "../../../json/validationErrors.json";
import countryCodes from "../../../utils/countryISDCode.json";
import moment from "moment/moment";


const messages = Messages.messages;
const useStyles = makeStyles({
  selectGrid: {
    flexGrow: 0,
    // maxWidth: "100%",
    flexBasis: "100%",
    overflowX: "hidden",
    width: 220,
  },
  declarationText: {
    fontSize: "14px",
    paddingTop: "10px",
    textAlign: "justify",
  },
  submitBtn: {
    marginBottom: 10,
    fontSize: 14,
  },
  hide: {
    display: "none",
  },
  headerTitle: {
    textTransform: "uppercase",
    // color: '#fff',
    fontSize: 18,
    fontWeight: "bold",
    flex: 3,
    padding: 8,
    // marginBottom: -5,
  },
});

export default function FormInfo(props) {
  return (
    <div>
      <FormikHoc {...props} />
    </div>
  );
}

const InfoForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    prog,
    setProg,
    academicPrograms,
  } = props;

  console.log(values.referralList,"dljofhbepfb");
  
  const classes = useStyles();
  const options = countryCodes.codes;

  const [loading, setLoading] = useState(false)


  console.log(values, "aaaaaaaaaaaaaaaaaa");


  const change = (name, e) => {
    e.persist();
    handleChange(e);
    console.log(name, e, "/////////////////////");
    if (name === "department") {
      let programs = [];
      // getPrograms(e.target.value).then((res) => {
      //   res.forEach((el) => {
      //     programs.push({
      //       label: el.Name,
      //       value: el.Name,
      //     });
      //   });
      //   console.log(programs);
      //   setProg(programs);
      // });
    }
    if (name === "mobile") {
      setMobile(e.target.value);
    }

    if (name === "country") {
      setFieldValue("state", "");
    }

    if (name === "state") {
      setFieldValue("city", "");
    }
    setFieldTouched(name, true, false);
  };
  const { t } = useTranslation();
  const [year, setYear] = useState([]);
  const [department, setDepartment] = useState([]);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = React.useState();
  const [showOtpEnter, setShowOtpEnter] = React.useState(false);
  const [changeSize, setChangeSize] = React.useState(4);
  const [changeBtnSize, setChangeBtnSize] = React.useState(2);
  const getCities = (value) => {
    // console.log("state for city", value);
    const cityStates = StateCityJson.states.find(
      (state) => state.value === value
    );
    let districts = cityStates?.districts || [];
    let cities = [];
    districts.forEach((district) => {
      cities.push({
        label: district,
        value: district,
      });
    });
    // console.log("cities", cities);
    return cities;
  };

  const getStates = (value) => {
    const statesObject = CountryStateJson.countries.find(
      (country) => country.country === value
    );
    let statesArray = statesObject?.states || [];
    let statesOfValue = [
      {
        label: "State",
        value: "State",
      },
    ];
    statesArray.map((state) => {
      statesOfValue.push({
        label: state,
        value: state,
      });
    });
    return statesOfValue;
  };
  React.useEffect(() => {
    if (otp) {
      alert(`Please note down the OTP for further process. The OTP is ${otp}`);
    }
  }, [otp]);
  // useEffect(() => {
  //   getYear().then((res) => {
  //     let yearRes = [];
  //     res.map((el) => {
  //       yearRes.push({ label: el.year, value: el.year });
  //     });
  //     setYear(yearRes);
  //   });

  //   getDepartment().then((res) => {
  //     let departmentRes = [];
  //     console.log(res);
  //     res.map((el) => {
  //       departmentRes.push({ label: el.Name, value: el.Id });
  //     });
  //     setDepartment(departmentRes);
  //   });
  // }, []);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };
  const handleOtp = async () => {
    // There are 2 onscreens change both
    setChangeSize(3);
    setChangeBtnSize(3);
    const otpto = "email";
    if (otpto === "email") {
      sendOtpTo(values.email, mobile, otpto)
        .then((res) => {
          if (res.success) {
            handleSnackbarOpen("success", t("OTP sent"));
            setShowOtpEnter(true);
          } else {
            handleSnackbarOpen("error", t("Unable to send OTP."));
          }
        })
        .catch((err) => {
          handleSnackbarOpen("error", t("OTP Error"));
        });
    } else {
      if (mobile.length === 10) {
        const registeredUser = false;
        if (!registeredUser) {
          console.log("registeredUser", registeredUser);
          if (otpto === GLOBAL.OTP.ON_SCREEN) {
            setShowOtpEnter(true);
            setOtp(otpGenerator());
          } else {
            sendOtpTo(values.email, mobile, otpto)
              .then((res) => {
                if (res.status === GLOBAL.RESPONSE_STATUS.SUCESS) {
                  handleSnackbarOpen("success", t("OTP sent"));
                  setShowOtpEnter(true);
                } else {
                  handleSnackbarOpen("error", t("Unable to send OTP."));
                }
              })
              .catch((err) => {
                handleSnackbarOpen("error", t("OTP Error"));
              });
          }
        } else {
          handleSnackbarOpen("error", t("Please check your Mobile Number"));
        }
      } else {
        handleSnackbarOpen("error", t("Please check your Mobile Number"));
      }
    }
  };
  const handleSnackbarOpen = (severity, message) => {
    setOpenSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };
  values.handleSnackbarOpen = (severity, message) => {
    setOpenSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  values.handleLogin = () => {
    console.log("login", values);
    submitForm();
    //   // There are 2 onscreens change both
    //   let otpto = "email";
    //   if (otpto === GLOBAL.OTP.ON_SCREEN) {
    //     if (otp === values.otp) {
    //       submitForm(mobile);
    //     } else {
    //       handleSnackbarOpen("error", t("Please Enter a valid OTP"));
    //       setShowOtpEnter(true);
    //     }
    //   } else {
    //     if (values.otp) {
    //       isOtpValid(values.email, values.mobile, otpto, values.otp).then(
    //         (res) => {
    //           if (res.status) {
    //             submitForm(mobile);
    //           } els
    //             handleSnackbarOpen("error", t("Please Enter a valid OTP"));
    //             setShowOtpEnter(true);
    //           }
    //         }
    //       );
    //     } else {
    //       handleSnackbarOpen("error", t("Enter OTP"));
    //       setShowOtpEnter(true);
    //     }
    //   }
  };

  const submitForm = () => {

    console.log("sssasdsdd", values);
    console.log("declaration", values.declaration);
    // setLoading(true);
    if (values.declaration) {
      
      setLoading(true);
      // let payload2 = {
      //   Email: values.email,
      //   Phone: `${values.countrycode}${values.mobile}`,
      //   AcademicYear: "2022",
      //   First_Name: values.firstName,
      //   Last_Name: values.lastName,
      //   Self: "Yes",
      //   Birthdate:values.dob,
      //   Caste__c:values.caste,
      // };
      let ageCal = Number(
        moment.duration(moment().diff(moment(`${values.dob}`, "YYYY"), "years"))
      );
      let payload2 = {
        FirstName: values.firstName,
        Middle_Name__c: values.middleName,
        LastName: values.lastName,
        hed__Former_Middle_Name__c: values.middleName,
        Phone: `${values.countrycode}${values.mobile}`,
        Birthdate: values.dob,
        MailingCountry: values.country,
        MailingState: values.state,
        MailingCity: values.city,
        Educational_Qualification__c: values.qualifications,
        Email: values.email,
        Caste__c: values.caste,
        hed__Primary_Address_Type__c: "Permanent",
        Course_List__c: values.courseList,
        accountID: "0015j00000hZClLAAW",
        Taluk_Tehsil__c: values.tehsil,
        Gram_Panchayat__c: values.gramPanchayat,
        Hamlet__c: values.hamlet,
         Village__c: values.village,
        hed__Religion__c: values.religion,
        Age__c: ageCal,
        Below_Poverty_Line__c: values.poverty,
        Emergency_Contact_Number__c: values.mobilePhone,
        House_Number__c:values.HouseNumber,
        MailingPostalCode:values.Pincode,
        hed__Gender__c:values.gender,
        MailingPostalCode:values.Pincode
      };
      createContact(payload2)
        .then((res) => {
          if (res.success) {
            let payload = {
              FirstName: values.firstName,
              Middle_Name__c: values.middleName,
              LastName: values.lastName,
              Company: "LTPCT",
              MobilePhone: `${values.countrycode}${values.mobile}`,
              Phone: `${values.countrycode}${values.mobile}`,
              Email: values.email,
              Email_ID__c: values.email,
              Date_of_Birth__c: values.dob,
              Country__c: values.country,
              State__c: values.state,
              City__c: values.city,
              Referral_Details__c: values.referralList,
              Course_List__c: values.courseList,
              Referral_Name__c: values.referralName,
              Mobiliser_Details__c: values.mobiliser,
              Caste__c: values.caste,
              Educational_Qualification__c: values.qualifications,
              Middle_Name__c: values.middleName,
              Taluk_Tehsil__c: values.tehsil,
              Gram_Panchayat__c: values.gramPanchayat,
              Hamlet__c: values.hamlet,
              Village__c: values.village,
              Religion__c: values.religion,
              Age__c: ageCal,
              Below_Poverty_Line__c: values.poverty,
              Alternate_Mobile__c: values.mobilePhone,
              House_Number__c:values.HouseNumber,
              PostalCode:values.Pincode,
              hed__Gender__c:values.gender,
              City:values.city,
              State:values.state,
              Country:values.country,
              Pincode__c:values.Pincode,
              // Emergency_Contact_Number__c: values.mobilePhone
              // firstName: values.firstName,
              // lastName: values.lastName,
              // Company: "Alliance EPGDM",
              // Status: "Verified",
              // LeadSource: "Web",
              // Phone: `${values.countrycode}${values.mobile}`,
              // Email: values.email,
              // Applying_To__c: "0015j00000AV98EAAT",
              // City: "Bangalore",
              // State: "Karnataka",
              // Country: "India",
              // University_Name__c: values.universityName,
              // Education_Qualification__c: values.educationQualification,
              // Work_Experience_Minimum_2_Years__c: values.workExp,
              // Course__c: values.academicPrograms,
              // Campus__c: "School of Business",
            };
            console.log("payload", payload);
            postDetails(payload)
              .then((res) => {
                setLoading(false);
                if (res.status === "success") {
                  props.setShowForm(false);
                  handleSnackbarOpen("success", "Registration Successful");
                } else {
                  values.handleSnackbarOpen("warning", "Something went wrong.");
                }
              })
              .catch((err) => {
                console.log("error", err);
                setLoading(false);
                values.handleSnackbarOpen("warning", "Something went wrong.");
              });
          } else {
            debugger
            setLoading(false);
            // alert("Existing Number.");
            handleSnackbarOpen("error", "The number already exists!");
            
          }
        })
        .catch((err) => {
          setLoading(false);
          // alert("Existing Number.");
          values.handleSnackbarOpen("error", "The number already exists!");
        });
    } else {
      setLoading(false);
      values.handleSnackbarOpen("error", "Please accept the declaration.");
    }
  };
  const [courses, SetCourses] = useState([
    { "label": "OFFICE ASSISTANT", "value": "OFFICE ASSISTANT" },
    { "label": "AUTO MECHANIC", "value": "AUTO MECHANIC" },
    { "label": "ELECTRICIAN", "value": "ELECTRICIAN" },
    { "label": "WELDER", "value": "WELDER" },
    { "label": "FITTER", "value": "FITTER" },
    { "label": "SELF EMPLOYED TAILORING", "value": "SELF EMPLOYED TAILORING" },
    { "label": "ASSISTANT BEAUTY THERAPIST", "value": "ASSISTANT BEAUTY THERAPIST" },
    { "label": "SOLAR PV MODULE MANUFACTURING TECHNICIAN", "value": "SOLAR PV MODULE MANUFACTURING TECHNICIAN" },
    { "label": "SOLAR PANEL TECHNICIAN", "value": "SOLAR PANEL TECHNICIAN" },
    { "label": "CCC(COURSE ON COMPUTER CONCEPT)", "value": "CCC(COURSE ON COMPUTER CONCEPT)" },
    { "label": "TALLY", "value": "TALLY" }
  ]);
  const [referrals, SetReferrals] = useState([
    { "label": "BANNER", "value": "BANNER" },
    { "label": "CALL", "value": "CALL" },
    { "label": "DIGITAL PLATFORMS", "value": "DIGITAL PLATFORMS" },
    { "label": "FRIENDS", "value": "FRIENDS" },
    { "label": "LOCAL LEADER", "value": "LOCAL LEADER" },
    { "label": "MARKETING TEAM", "value": "MARKETING TEAM" },
    { "label": "PAMPHLET", "value": "PAMPHLET" },
    { "label": "RELATIVES", "value": "RELATIVES" },
    { "label": "SARPANCH", "value": "SARPANCH" },
    { "label": "SCHOOL PRINCIPAL", "value": "SCHOOL PRINCIPAL" },
    { "label": "SEMINAR", "value": "SEMINAR" },
    { "label": "TEXT MESSAGE", "value": "TEXT MESSAGE" },
    { "label": "TRAINER /STAFF", "value": "TRAINER /STAFF" },
    { "label": "WEBSITE", "value": "WEBSITE" },
    { "label": "NA", "value": "NA" }
  ]);
  const [mobiliser, SetMobiliser] = useState([
    { "label": "ADMISSION EXECUTIVE 1", "value": "ADMISSION EXECUTIVE 1" },
    { "label": "ADMISSION EXECUTIVE 2", "value": "ADMISSION EXECUTIVE 2" },
    { "label": "ADMISSION EXECUTIVE 3", "value": "ADMISSION EXECUTIVE 3" },
    { "label": "ADMISSION EXECUTIVE 4", "value": "ADMISSION EXECUTIVE 4" },
    { "label": "ADMISSION EXECUTIVE 5", "value": "ADMISSION EXECUTIVE 5" },
    { "label": "ADMISSION EXECUTIVE 6", "value": "ADMISSION EXECUTIVE 6" },
    { "label": "ADMISSION EXECUTIVE 7", "value": "ADMISSION EXECUTIVE 7" },
    { "label": "ADMISSION EXECUTIVE 8", "value": "ADMISSION EXECUTIVE 8" },
    { "label": "NA", "value": "NA" }

  ]);
  const [tehsilOptions, SetTehsilOptions] = useState([
    { label: "a", value: "a" },
    { label: "b", value: "b" },
    { label: "c", value: "c" },
    { label: "d", value: "d" },
  ]);

  const [gramPanchayat, SetgramPanchayat] = useState([
    { "label": "KRISHNA JAGDISHBHAI AHIR", "value": "KRISHNA JAGDISHBHAI AHIR" },
    { "label": "DHRUMIN THAKORBHAI DHANGAR", "value": "DHRUMIN THAKORBHAI DHANGAR" },
    { "label": "NEEL KETANBHAI RAVAL", "value": "NEEL KETANBHAI RAVAL" },
    { "label": "BHAVNABEN THAKORBHAI DHANGAR", "value": "BHAVNABEN THAKORBHAI DHANGAR" },
    { "label": "BHOOMIBEN NARENDRABHAI TAILOR", "value": "BHOOMIBEN NARENDRABHAI TAILOR" },
    { "label": "GANDHI MAITRY VATSAL", "value": "GANDHI MAITRY VATSAL" },
    { "label": "NA", "value": "NA" }
  ]);

  const [religionOptions, setReligionOptions] = useState([
    
    // { "label": "ATHEIST", "value": "ATHEIST" },
    //{ "label": "BUDDHIST", "value": "BUDDHIST" },
    { "label": "CHRISTIAN", "value": "CHRISTIAN" },
    { "label": "HINDU", "value": "HINDU" },
    { "label": "JAIN", "value": "JAIN" },
    { "label": "JEWISH", "value": "JEWISH" },
    { "label": "MUSLIM", "value": "MUSLIM" },
    { "label": "PARSI", "value": "PARSI" },
    { "label": "SIKH", "value": "SIKH" }, 
    // { "label": "OTHER", "value": "OTHER" },
    // { "label": "NA", "value": "NA" }
  ]);
  const [caste, SetCaste] = useState([
    { "label": "BC", "value": "BC" },
    { "label": "DO NOT WISH TO SPECIFY", "value": "DO NOT WISH TO SPECIFY" },
    { "label": "GENERAL", "value": "GENERAL" }, 
    { "label": "OBC", "value": "OBC" },
    { "label": "OC", "value": "OC" },
    { "label": "SC", "value": "SC" },
    { "label": "ST", "value": "ST" },
    { "label": "NA", "value": "NA" }
  ]);
  console.log(caste, "CASTE");

  const [poverty, SetPoverty] = useState([
    { "label": "YES", "value": "YES" },
    { "label": "NO", "value": "NO" },
    // { "label": "NA", "value": "NA" }
  ]);

  const [qualifications, setQualifications] = useState([
    { "label": "LESS THAN 8TH PASS", "value": "LESS THAN 8TH PASS" },
    { "label": "8TH PASS", "value": "8TH PASS" },
    { "label": "9TH PASS", "value": "9TH PASS" },
    { "label": "10TH PASS", "value": "10TH PASS" },
    { "label": "12TH SCIENCE PASS", "value": "12TH SCIENCE PASS" },
    { "label": "12TH COMMERCE PASS", "value": "12TH COMMERCE PASS" },
    { "label": "12TH ARTS PASS", "value": "12TH ARTS PASS" },
    { "label": "ITI", "value": "ITI" },
    { "label": "DIPLOMA", "value": "DIPLOMA" },
    { "label": "GRADUATE", "value": "GRADUATE" },
    { "label": "POST GRADUATE", "value": "POST GRADUATE" },
    { "label": "NA", "value": "NA" }
  ]);
  
  const [cityOp, setCityOp] = useState([]);
  const [statesOp, setStatesOp] = useState([]);
  const [talukaOp, setTalukaOp] = useState([]);
  const [GramPanchayatOp, setGramPanchayatOp] = useState([]);
  const [stateData, setStateData] = useState("");

  useEffect(() => {
    let setState = [];
    getStatesNew()
      .then((res) => {
        res.map((item) => {
          setState.push({ label: item, value: item });
        });
        setStatesOp(setState);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  useEffect(() => {
    if (values.state) {
      let setState = [];
      getCityNew(values.state)
        .then((res) => {
          // console.log('citydata',res);
          res.map((item) => {
            setState.push({ label: item, value: item });
          });
          setCityOp(setState);
          console.log("data", setState);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [values.state]);
  useEffect(() => {
    if (values.state && values.city) {
      let setState = [];
      // const tempCity = values.city?.charAt(0).toUpperCase() + values.city.toLowerCase().slice(1);
      const tempCity = values.city?.split(" ").map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      }).join(" ");

      console.log("getTaluka", tempCity);

      getTaluka(values.state, tempCity)
        .then((res) => {
          res.map((item) => {
            setState.push({ label: item, value: item });
          });
          setTalukaOp(setState);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [values.state && values.city]);
  useEffect(() => {
    if (values.state && values.city && values.tehsil) {
      let setState = [];
      // const tempTaluk = values.tehsil?.charAt(0).toUpperCase() + values.tehsil.toLowerCase()?.slice(1)
      // const tempCity = values.city?.charAt(0).toUpperCase() + values.city.toLowerCase().slice(1)      
      const tempTaluk = values.tehsil?.split(" ").map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      }).join(" ");
      const tempCity = values.city?.split(" ").map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      }).join(" ");
      getvillage(values.state, tempCity, tempTaluk)
        .then((res) => {
          res.map((item) => {
            setState.push({ label: item, value: item });
          });
          setGramPanchayatOp(setState);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [values.state && values.city && values.tehsil]);
  console.log("GramPanchayatOp", GramPanchayatOp);

  function convert(arr) {
    return arr
      .map(item => ({
        label: item.label.toUpperCase(),
        value: item.value.toUpperCase()
      }))
      // .sort((a, b) => a.label.localeCompare(b.label));
      .sort((a, b) => {
        if (a.label === 'NA') return 1;
        if (b.label === 'NA') return -1; 
        return a.label.localeCompare(b.label); 
      });
  }
  const CityProcessed = convert(cityOp);
  const TalukaProcessed = convert(talukaOp);
  const VillageProcessed = convert(GramPanchayatOp);

  console.log("CityProcessed:", CityProcessed);
  console.log("TalukaProcessed:", TalukaProcessed);
  console.log("VillageProcessed:", VillageProcessed);

  return (
    <React.Fragment>
      {loading && <KenLoader/>}
      <form style={{ position: "relative" }} className="au-form">
        {values.loading && <KenLoader size={24} />}
        <div style={{ margin: "0px 0px 0px 0px" }}>
          <Typography className={classes.headerTitle}>Enquiry Form</Typography>
          <Grid container style={{ padding: "12px 24px" }}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <div className="form-field">
                  <KenInputField
                    required={true}
                    // onChange={change.bind(null, "firstName")}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                      change.bind(null, "firstName")(e);
                    }}
                    name="firstName"
                    value={values.firstName}
                    placeholder="Enter First Name"
                    label="First Name"
                    errors={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="form-field">
                  <KenInputField
                    required={false}
                    // onChange={change.bind(null, "middleName")}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                      change.bind(null, "middleName")(e);
                    }}
                    name="middleName"
                    placeholder="Enter Middle Name"
                    value={values.middleName}
                    label="Middle Name"
                    errors={errors.middleName}
                    touched={touched.middleName}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="form-field">
                  <KenInputField
                    required={true}
                    // onChange={change.bind(null, "lastName")}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                      change.bind(null, "lastName")(e);
                    }}
                    name="lastName"
                    placeholder="Enter Last Name"
                    value={values.lastName}
                    label="Last Name"
                    errors={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <KenInputField
                  required={false}
                  onChange={change.bind(null, "email")}
                  name="email"
                  value={values.email}
                  label="Email"
                  placeholder="Enter Email"
                  errors={errors.email}
                  touched={touched.email}
                />
              </Grid>
                <Grid item sm={6} xs={12}>
                  <KenInputField
                    required={true}
                    label="Phone"
                    name="mobile"
                    placeholder="Enter Mobile number"
                    value={values.mobile}
                    onChange={change.bind(null, "mobile")}
                    startAdornment
                    errors={errors.mobile}
                    touched={touched.mobile}
                    startAdornmentIcon={
                      <div style={{ marginTop: "-4px", marginBottom: "0px" }}>
                        <KenSelect
                          disabled={true}
                          optionalLabel={false}
                          options={options}
                          value={values.countrycode}
                          name="countrycode"
                          onChange={change.bind(null, "countrycode")}
                          defaultValue={() => {
                            setFieldValue("countrycode", "91");
                            return "+91";
                          }}
                        />
                      </div>
                    }
                  />
                </Grid>
              <Grid item sm={6} xs={12}>
                <KenInputField
                  required={false}
                  label="Additional Mobile Number"
                  name="mobilePhone"
                  placeholder="Enter Mobile number"
                  value={values.mobilePhone}
                  onChange={change.bind(null, "mobilePhone")}
                  startAdornment
                  errors={errors.mobilePhone}
                  touched={touched.mobilePhone}
                  startAdornmentIcon={
                    <div style={{ marginTop: "-4px", marginBottom: "0px" }}>
                      <KenSelect
                        disabled={true}
                        optionalLabel={false}
                        options={options}
                        value={values.countrycode1}
                        name="countrycode"
                        onChange={change.bind(null, "countrycode1")}
                        defaultValue={() => {
                          setFieldValue("countrycode1", "91");
                          return "+91";
                        }}
                      />
                    </div>
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.selectGrid}>
                <KenInputField
                  required={true}
                  onChange={change.bind(null, "dob")}
                  name="dob"
                  value={props.values.dob}
                  type="date"
                  placeholder="mm/dd/yy"
                  label="Date Of Birth"
                  errors={errors.dob}
                  touched={touched.dob}
                />
              </Grid>
              {props.values.dob && props.values.dob ? (
                <Grid item sm={6} xs={12} className={classes.selectGrid}>
                  <KenInputField
                    required={true}
                    disabled={true}
                    onChange={change.bind(null, "age")}
                    name="age"
                    value={
                      props.values.age ||
                      Number(
                        moment.duration(
                          moment().diff(
                            moment(`${props.values.dob}`, "YYYY"),
                            "years"
                          )
                        )
                      )
                    }
                    label="Age"
                    errors={errors.age}
                    touched={touched.age}
                  />
                </Grid>
              ) : (
                ""
              )}
              
              
              <Grid item sm={6} xs={12} style={{ display: 'flex', alignItems: 'center' }}>

  <KenRadioGroup
    required={true}
    name="gender"
    label={(
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ color: '#B92500' }}> *</span>Gender
      {touched.gender && errors.gender && (
        <span style={{ color:'#B92500', marginLeft: '8px' }}>
          {errors.gender}</span>  
      )}
    </div>
    )}
    value={values.gender}
    errors={errors.gender}
    touched={touched.gender}
    onChange={(event) => {
      setFieldValue('gender', event.target.value);
    }}
    options={[
      { value: 'MALE', label: 'Male' },
      { value: 'FEMALE', label: 'Female' },
      { value: 'OTHER', label: 'Other' },

    ]}
  />

</Grid>


              <Grid item sm={6} xs={12} className={classes.selectGrid}>
                <KenSelect
                  required={false}
                  name="country"
                  value={values.country}
                  label="Country"
                  placeholder="Select"
                  options={CountryJson.countries}
                  defaultValue={() => {
                    setFieldValue("country", "INDIA");
                    return "INDIA";
                  }}
                  disabled={true}
                  // errors={errors.country}
                  // touched={touched.country}
                  onChange={change.bind(null, "country")}
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.selectGrid}>
                <KenSelect
                  required={true}
                  name="state"
                  value={values.state}
                  label=" State"
                  placeholder="Select"
                  // options={stateCity.states}
                  options={statesOp}
                  errors={errors.state}
                  touched={touched.state}
                  onChange={change.bind(null, "state")}
                />
              </Grid>
              {/* <Grid item sm={6} xs={12} className={classes.selectGrid}> */}
              {values?.country?.toUpperCase() === "INDIA" ? (
                <>
                  {values?.state && cityOp.length !== 0 ? (
                    <Grid item sm={6} xs={12} className={classes.selectGrid}>
                      <KenSelect
                        required={true}
                        name="city"
                        value={values.city}
                        label="City/District"
                        options={CityProcessed}
                        errors={errors.city}
                        touched={touched.city}
                        onChange={change.bind(null, "city")}
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={6} xs={12}>
                      <div className="form-field">
                        <KenSelect
                          required={true}
                          onChange={change.bind(null, "city")}
                          name="city"
                          placeholder="Enter City Name"
                          value={values.city}
                          label="City"
                          errors={errors.city}
                          touched={touched.city}
                        />
                      </div>
                    </Grid>
                  )}
                  {values?.city && talukaOp.length !== 0 ? (
                    <Grid item sm={6} xs={12} className={classes.selectGrid}>
                      <KenSelect
                        required={true}
                        name="tehsil"
                        value={values.tehsil}
                        label="Tehsil/Taluka"
                        options={TalukaProcessed}
                        errors={errors.tehsil}
                        touched={touched.tehsil}
                        onChange={change.bind(null, "tehsil")}
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={6} xs={12}>
                      <div className="form-field">
                        <KenSelect
                          required={true}
                          name="tehsil"
                          value={values.tehsil}
                          label="Tehsil/Taluka"
                          options={TalukaProcessed}
                          errors={errors.tehsil}
                          touched={touched.tehsil}
                          onChange={change.bind(null, "tehsil")}
                        />
                      </div>
                    </Grid>
                  )}
                  {values?.tehsil && GramPanchayatOp.length !== 0 ? (
                    <Grid item sm={6} xs={12} className={classes.selectGrid}>
                      <KenSelect
                        required={true}
                        name="gramPanchayat"
                        value={values.gramPanchayat}
                        label="Village"
                        options={VillageProcessed}
                        errors={errors.gramPanchayat}
                        touched={touched.gramPanchayat}
                        onChange={change.bind(null, "gramPanchayat")}
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={6} xs={12}>
                      <div className="form-field">
                        <KenSelect
                          required={true}
                          name="gramPanchayat"
                          value={values.gramPanchayat}
                          label="Village"
                          options={GramPanchayatOp}
                          errors={errors.gramPanchayat}
                          touched={touched.gramPanchayat}
                          onChange={change.bind(null, "gramPanchayat")}
                        />
                      </div>
                    </Grid>
                  )}
                  {/* {values?.gramPanchayat ? (
                    <Grid item sm={6} xs={12} className={classes.selectGrid}>
                      <KenInputField
                        // required={true}
                        name="village"
                        value={values.village}
                        label="Village"
                        errors={errors.village}
                        touched={touched.village}
                        onChange={change.bind(null, "village")}
                      />
                    </Grid>
                  ) : (
                    ""
                  )} */}
                  {values?.gramPanchayat ? (
                    <Grid item sm={6} xs={12} className={classes.selectGrid}>
                      <KenInputField
                        // required={true}
                        name="hamlet"
                        value={values.hamlet}
                        label="Hamlet"
                        errors={errors.hamlet}
                        touched={touched.hamlet}
                        // onChange={change.bind(null, "hamlet")}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          change.bind(null, "hamlet")(e);
                        }}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Grid item sm={6} xs={12} className={classes.selectGrid}>
                  <KenInputField
                    required={false}
                    name="city"
                    value={values.city}
                    label="City/District"
                    errors={errors.city}
                    touched={touched.city}
                    // onChange={change.bind(null, "city")}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                      change.bind(null, "city")(e);
                    }}
                  />
                </Grid>
              )}
              {/* </Grid> */}
              <Grid item sm={6} xs={12}>
                <KenInputField
                  required={false}
                  // onChange={change.bind(null, "HouseNumber")}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                    change.bind(null, "HouseNumber")(e);
                  }}
                  name="HouseNumber"
                  value={values.HouseNumber}
                  label="House Number"
                  placeholder="Enter House Number"
                  errors={errors.HouseNumber}
                  touched={touched.HouseNumber}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <KenInputField
                  required={true}
                  onChange={change.bind(null, "Pincode")}
                  name="Pincode"
                  value={values.Pincode}
                  label="Pincode"
                  placeholder="Enter a Pincode"
                  errors={errors.Pincode}
                  touched={touched.Pincode}
                />
              </Grid>


              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={true}
                    name="qualifications"
                    value={values.qualifications}
                    label="Qualifications"
                    placeholder="Select"
                    options={qualifications}
                    errors={errors.qualifications}
                    touched={touched.qualifications}
                    onChange={change.bind(null, "qualifications")}
                  />
                </div>
              </Grid> 
              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={true}
                    name="courseList"
                    value={values.courseList}
                    label="Applying to"
                    placeholder="Select"
                    options={courses}
                    errors={errors.courseList}
                    touched={touched.courseList}
                    onChange={change.bind(null, "courseList")}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={true}
                    name="referralList"
                    value={values.referralList}
                    label="Referral Details"
                    placeholder="Select"
                    options={referrals}
                    errors={errors.referralList}
                    touched={touched.referralList}
                    onChange={change.bind(null, "referralList")}
                  />
                </div>
              </Grid>
              {(values.referralList == "FRIENDS" ||
                values.referralList == "RELATIVES" ||
                values.referralList == "SARPANCH" ||
                values.referralList == "SCHOOL PRINCIPAL" ||
                values.referralList == "LOCAL LEADER" ||
                values.referralList == "MARKETING TEAM" ||
                values.referralList == "TRAINER /STAFF" ||
                values.referralList == "DIGITAL PLATFORMS" ||
                values.referralList == "WEBSITE" ||
                values.referralList == "TEXT MESSAGE" ||
                values.referralList == "CALL" ||
                values.referralList == "PAMPHLET" ||
                values.referralList == "BANNER" ||
                values.referralList == "SEMINAR") && (
                  <Grid item sm={6} xs={12}>
                    <div className="form-field">
                      <KenInputField
                        required={false}
                        // onChange={change.bind(null, "referralName")}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          change.bind(null, "referralName")(e);
                        }}
                        name="referralName"
                        placeholder="Enter Referral Name"
                        value={values.referralName}
                        label="Referral Name"
                        errors={errors.referralName}
                        touched={touched.referralName}
                      />
                    </div>
                  </Grid>
                )
              }             

              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={true}
                    name="mobiliser"
                    value={values.mobiliser}
                    label="Mobiliser Details"
                    placeholder="Select"
                    options={mobiliser}
                    errors={errors.mobiliser}
                    touched={touched.mobiliser}
                    onChange={change.bind(null, "mobiliser")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={false}
                    name="religion"
                    value={values.religion}
                    label="Religion"
                    placeholder="Select"
                    options={religionOptions}
                    // errors={errors.religion}
                    // touched={touched.religion}
                    onChange={change.bind(null, "religion")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={false}
                    name="caste"
                    value={values.caste}
                    label="Caste"
                    placeholder="Select"
                    options={caste}
                    // errors={errors.caste}
                    // touched={touched.caste}
                    onChange={change.bind(null, "caste")}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="form-field">
                  <KenSelect
                    required={false}
                    name="poverty"
                    value={values.poverty}
                    label="Below Poverty Line"
                    placeholder="Select"
                    options={poverty}
                    // errors={errors.poverty}
                    // touched={touched.poverty}
                    onChange={change.bind(null, "poverty")}
                  />
                </div>
              </Grid>

              <Grid item xs={12} style={{ display: "flex" }}>
                <div className="form-checkbox">
                  <KenCheckbox
                    required={true}
                    name="declaration"
                    value={values.declaration}
                    onChange={(event) => {
                      setFieldValue("declaration", event.target.checked);
                    }}
                  ></KenCheckbox>
                </div>
                <Typography className={classes.declarationText}>
                  I agree to receive information from LTPCT
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <KenButton
            className={classes.submitBtn}
            style={{ fontSize: 14, backgroundColor: "#192339" }}
            variant="primary"
            onClick={() => {
              console.log("errors", errors);
              if((errors && Object.keys(errors).length > 0)) {
                handleSnackbarOpen("error", t("Please fill all the required fields."));
                handleSubmit();
              } else {
                handleSubmit();
              }
            }}
          >
            Submit
          </KenButton>
        </div>
      </form>
      <KenSnackBar
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={5000}
        open={openSnackbar}
        handleSnackbarClose={handleSnackbarClose}
        position="Bottom-Right"
      />
    </React.Fragment>
  );
};

const FormikHoc = (props) => {
  let firstName;
  // let middleName;
  let lastName;
  let dob;
  // let email;
  let mobile;
  let country;
  let countrycode;
  let state;
  let city;
  let handleSnackbarOpen;
  let referralList;
  // let referralName;
  let courseList;
  let mobiliser;
  // let hamlet;
  // let caste;
  let poverty;
  let qualifications;
  let tehsil;
  let mobilePhone;
  let gramPanchayat;
  let HouseNumber;
  let pincode;
  let gender;
  // let religion;
  // let village;
  let age;
  let countrycode1;

  const [toggle, setToggle] = useState(true);
  const stringRegExp = /^[A-Z. ( ) - ]*$/;
  // const houseRegExp = /^\d+([ -/]?\d*[a-zA-Z]?)?$/;
  // const houseRegExp = /^[0-9a-zA-Z\s\-/,.]*$/;
  const houseRegExp = /^[a-zA-Z0-9#,\-./\s]*$/;
  const dateRegExp = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-2020$/;
  // const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/; accepts all number
  const phoneRegExp =/^(\+91[- ]?)?[6789]\d{9}$/; //accept indian number only
  const pincodeExp = /^[0-9]{6,6}$/;
  // const tehsil_Regex= /^[A-Z -]*$/;

  const fullNameExp = /^(\w+\s+\w+)$/;
  const emailExp = /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,}$/
  const [program, setProgram] = useState();
  const [academicPrograms, setacademicPrograms] = useState("EPGDM");
  let [loading, setLoading] = useState(false);
  const [prog, setProg] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);


  const age_regex = /^\S[0-9]{0,3}$/;

  const FormikForm = withFormik({
    mapPropsToValues: () => ({
      firstName: firstName,
      lastName: lastName,
      // email: email,
      dob: dob,
      countrycode: countrycode,
      mobile: mobile,
      mobilePhone: mobilePhone,
      country: country,
      state: state,
      city: city,
      courseList: courseList,
      referralList: referralList,
      // referralName: referralName,
      mobiliser: mobiliser,
      gramPanchayat: gramPanchayat,
      age: age,
      // religion: religion,
      // hamlet: hamlet,
      // village: village,
      // middleName: middleName,
      // caste: caste,
      poverty: poverty,
      HouseNumber: HouseNumber,
      Pincode: pincode,
      qualifications: qualifications,
      gender: gender,
      tehsil: tehsil,
      handleSnackbarOpen: handleSnackbarOpen,
      declaration: false,
      loading: loading,
      toggle: toggle,
      setToggle: setToggle,
      // statesOp: statesOp,
      // setStatesOp: setStatesOp,
    }),

    validationSchema: () =>
      Yup.object().shape({
        firstName: Yup.string()
          .matches(stringRegExp, messages?.name?.match)
          .max(15, messages?.firstName|| "Max 15 alphabets are allowed")
          .required(messages?.required),

        middleName: Yup.string()
          .matches(stringRegExp, messages?.name?.match)
          .max(15, messages?.middleName|| "Max 15 alphabets are allowed"),
        //   .required(messages?.required),
        lastName: Yup.string()
          .matches(stringRegExp, messages?.name?.match)
          .max(15, messages?.lastName|| "Max 15 alphabets are allowed")
          .required(messages?.required),
          email: Yup.string()
          .matches(emailExp,"Enter a valid email address")
          .max(150,messages?.email|| "Must be 150 characters or less"),
          // .email(messages?.email).required(messages?.required),
        mobile: Yup.string()
          .matches(phoneRegExp, messages?.mobile)
          .required(messages?.required),
        mobilePhone: Yup.string()
          .matches(phoneRegExp, messages?.mobile)
          .notOneOf([Yup.ref("mobile")], "Mobile number must be different"),
        // dob: Yup.string()
        //   .matches(dateRegExp, messages?.date?.match)
        //   .required(messages?.required),
        dob: Yup.date()
          .max(new Date("2020-10-28"), "Please enter a valid date")
          .required("Date is required"),
            gender: Yup.mixed()
              .oneOf(["MALE", "FEMALE", "OTHER"], "Please select a valid option") 
              .required(messages?.gender || "Gender is required"),
         //country: Yup.string().nullable().required(messages?.required),
        state: Yup.string().nullable().required(messages?.required),
        city: Yup.string()
          .matches(messages?.match)
          .nullable()
          .required(messages?.required),
        tehsil: Yup.string()
          .matches(messages?.match)
          .nullable()
          .required(messages?.required),
        HouseNumber:Yup.string()
        .matches(houseRegExp,"Please enter a valid format")
        .max(10, messages?.HouseNumber|| "Must be 10 characters or less"),
        Pincode : Yup.string()
        .matches(pincodeExp,"Please enter a valid Pincode")
        .required(messages?.required),
        qualifications: Yup.string().nullable().required(messages?.required),
        courseList: Yup.string().nullable().required(messages?.required),
        referralList: Yup.string().nullable().required(messages?.required),
        mobiliser: Yup.string().nullable().required(messages?.required),
        gramPanchayat: Yup.string()
          .matches(messages?.match)
          .nullable()
          .required(messages?.required),
        hamlet: Yup.string()
          .matches(stringRegExp, messages?.name?.match)
          .max(50, messages?.hamlet|| "Max 50 alphabets are allowed"),
        village: Yup.string()
          .matches(stringRegExp, messages?.name?.match)
          .max(50, messages?.name?.max),
        referralName: Yup.string()
          .matches(stringRegExp, messages?.name?.match)
          .max(20,"Max 20 alphabets are allowed"),
          

        // poverty: Yup.string().nullable().required(messages?.required),
        // hamlet: Yup.string().nullable().required(messages?.required),
        // religion: Yup.string().nullable().required(messages?.required),
        // village: Yup.string().nullable().required(messages?.required),
        // age: Yup.string()
        //   .matches(age_regex, messages?.age?.range)
        //   .required(messages?.required),

        // educationQualification: Yup.string()
        //   .nullable()
        //   .required(messages?.required),
        // workExp: Yup.string().nullable().required(messages?.required),
        // universityName: Yup.string().nullable(),
        // academicPrograms: Yup.string().nullable(),


      }),
    handleSubmit: (values) => {
      values.handleLogin();
    },
    displayName: "InfoForm",
  })(InfoForm);

  return (
    <FormikForm
      {...props}
      prog={prog}
      academicPrograms={academicPrograms}
      setProg={setProg}
      setLoading={setLoading}

    />

  );
};
