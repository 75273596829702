import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    position: 'absolute',
    zIndex: 110,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255,255,255,0.8)',
    minHeight: "100vh",
    height: "100%"
  }
}));

const KenLoader = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress {...props} />
    </div>
  );
}

export default KenLoader;