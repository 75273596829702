import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00218D',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00218D',
        },
    },
    formControlLabel: {
        '& .MuiFormControlLabel-label': {
          fontSize: '12px', // Adjust this value as needed
          color: 'black',
        },
    },
    // Style for the group label
    formLabel: {
        '&.MuiFormLabel-root': {
            fontSize: '12px',  // Set font size to 12px
            color: 'black',    // Set color to black
        }
    },
});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

// TODO: Make it to align with global component styles
export default function KenRadioGroup(props) {
    const { name, label, value, options = [],required, onChange } = props;
    const classes = useStyles();

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formLabel}>{label}</FormLabel>
            <RadioGroup
                name={name}
                onChange={onChange}
                row
                required={true}
                value={value}
                aria-label={label}
            >
                {options.map((option, index) => {
                    return (
                        <div>
                            <FormControlLabel
                                value={option.value || option}
                                control={<StyledRadio />}
                                label={option.label || option}
                                disabled={option.disabled}
                                key={index}
                                className={classes.formControlLabel}
                            />
                        </div>
                    )
                })}
            </RadioGroup>
        </FormControl>
    );
}