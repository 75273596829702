import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const KenButtonDefault = styled(Button)`
  border-radius: 3px;
  height: 35px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding: 8px 12px;
  text-transform: none;
`;

const KenButtonSecondary = styled(KenButtonDefault)`
  border: 1px solid ${(props) => props.theme.palette.KenColors.neutral60};
`;

export default function KenButton(props) {
  const { variant, icon, label, children } = props;
  //console.log(props);
  const propsCopy = Object.assign({}, props);
  if (icon) {
    propsCopy.startIcon = <img src={icon} alt="icon" />;
  }

  switch (variant) {
    case "primary":
      return (
        <KenButtonDefault
          data-testid="button"
          {...propsCopy}
          color="primary"
          variant="contained"
          disableElevation
        >
          {label || children}
        </KenButtonDefault>
      );
    case "secondary":
      return (
        <KenButtonSecondary
          data-testid="button"
          {...propsCopy}
          variant="outlined"
          color="primary"
        >
          {label || children}
        </KenButtonSecondary>
      );

    default:
      return (
        <KenButtonDefault data-testid="button" {...propsCopy} color="primary">
          {label || children}
        </KenButtonDefault>
      );
  }
}

// Sample json
// property icon: should be remote url in dynamic/json
const button = {
  variant: "primary",
  label: "Submit",
  disabled: true,
  icon: "https://img.icons8.com/material-outlined/24/000000/cloud-network.png",
};
