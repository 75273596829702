export const GLOBAL = {
  APPLICANT_TYPE: {
    FOREIGNER: 'foreigner',
    INDIAN: 'indian',
    DEFAULT: 'indian',
  },
  CONTACT_TYPE: {
    NEW: 'New',
    EXISTING: 'Existing',
  },
  ATTRIBUTES: {
    PLACE_OF_BIRTH: 'PlaceOfBirth',
    NATURE_OF_DEFORMITY: 'NatureOfDeformity',
    PERCENTAGE_OF_DEFORMITY: 'PercentageOfDeformity',
  },
  GENDER: {
    DEFAULT: '',
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other',
    DO_NOT_WISH_TO_DISCLOSE: 'Do not wish to disclose',
  },
  AGE_LIMIT: {
    YEARS: 17,
    MONTHS: 0,
    DAYS: 0,
  },
  NATIONALITY: {
    INDIAN: 'indian',
    FOREIGNER: 'austria',
    DEFAULT: 'indian',
  },
  COUNTRY: {
    INDIA: 'indian',
  },
  DOMICILE: {
    DEFAULT: 'karnataka',
  },
  CONFIRMATION: {
    YES: 'yes',
    NO: 'no',
  },
  STRING_FLAG: {
    TRUE: 'true',
    NULL: 'null',
  },
  REGEX: {
    NAME: /^[a-zA-Z ]*$/,
    MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    PINCODE: /^[0-9]{6,6}$/,
    NUMBER: /[^0-9]/g,
    DIGITS_ONLY_UPTO_100: /^\-?(([0-9]\d?|0\d{1,2})((\.)\d{0,2})?|100((\.)0{1,2})?)$/,
    DIGITS_ONLY_UPTO_10: /^\-?((0\d|\d)?((\.)\d{0,2})?|10((\.)0{1,2})?)$/,
    DIGITS_ONLY_UPTO_4: /^\-?((0[0-3]|[0-3])?((\.)\d{0,2})?|(4|04)((\.)0{1,2})?)$/,
    PERCENTAGE: /^100$/,
    CGPA: /^10$/,
    GRADE: /^[a-fA-FOoPp]$/,
    MIXED: /^[a-zA-Z0-9-./, ]*$/,
    PARENT_DOC: /^aadhaarParent/,
    PHONE_NUMBER: /^[0-9]*$/,
  },
  REASONS: {
    CLICKAWAY: 'clickaway',
  },
  RELATIONS: {
    FATHER: 'father',
    MOTHER: 'mother',
    GUARDIAN: 'guardian',
    SELF: 'self',
    PARENTS: 'parent',
    SON: 'son',
  },
  CASTS: {
    SC: 'sc',
    ST: 'st',
    OBC: 'obc',
  },
  ADDRESS_TYPES: {
    RESIDENTIAL: 'residential',
    PERMANENT: 'permanent',
  },
  MAX_MARKS: {
    CGPA: '10',
    PERCENTAGE: '100',
    GRADE: 'o',
    GPA: '4',
  },
  MARKING_SCHEMES: {
    CGPA: 'cgpa',
    PERCENTAGE: 'percentage',
    GRADE: 'grade',
    GPA: 'gpa',
  },
  DATE_PICKER: {
    MAX_DETAILS: 'year',
    MAX_DATE: new Date(),
  },
  DATE_FORMATS: {
    DEFAULT: 'yyyy-MM-dd',
    PASSING_YEAR_MONTH: 'YYYY-MM',
    DATE_OF_DECLARATION: 'dddd, MMMM Do YYYY',
    DOB: 'YYYY-MM-DD',
  },
  EDUCATIONS: {
    PASSING_YEAR: 'Passing Year',
    QUALIFYING_EXAM: "Qualifying Exam",
    BOARD: "Board",
    MARKING_SCHEME: "Marking Scheme",
    SCORE_OBTAINED: "Score Obtained",
    MAXIMUM_MARKS: "Maximum Marks",
    RESULT_AWAITED: "Result Awaited",
    STREAM:"Stream",
    INSTITUTE:"Institute"
  },
  DOCUMENTS: {
    PASSPORT_SIZE_PHOTO: 'passportPhoto',
    SSLC: "sslcMarkSheet",
    PUC1: "puc1Marksheet",
    PUC2: "puc2Marksheet",
    AADHAR: "aadhar",
    SIGNATURE: 'signature',
    BIRTH_CERT: 'birthCert',
    SCHOOL_RECORDS: 'schoolRecords',
    CASTE: 'caste',
    DOMICILE: 'migration',
    VISA: "visa",
    EMBASSY: "embassy",
    PASSPORT: "passport",
    PARENT: 'parent',
    ALLOWED_FILE_TYPES: ['image/pdf', 'image/png', 'image/jpeg'],
    MAX_FILE_SIZE: 5000000,
  },
  CURRENCY: {
    CURRENCY_CODEC: {
      DEFAULT: 'INR',
      DEFAULT_FOREIGN: 'USD',
      INR: 'INR',
    },
    CURRENCY_SYMBOL: {
      DEFAULT: '₹',
    },
  },
  PAYMENT: {
    GATEWAY_TYPE: 'razorpay',
  },
  APPLICATION_STATUS: {
    INCOMPLETE: 'incomplete',
    INCOMPLETE_STATUS:'Application Form Incomplete',
    SUBMITTED: 'submitted',
    SUBMITTED_STATUS:'Application Form Submitted',
    STARTED:'Application Started'
  },
  QUAL_CODES: {
    DIPLOMA: 'DIP',
    SSC: 'SSC',
    HSC: 'HSC',
  },
  QUAL_EXAMS: {
    DIPLOMA: 'diploma',
  },
  SUBJECTS: {
    PHYSICS: 'PHYSICS',
    MATHEMATICS: 'MATHEMATICS',
    CHEMISTRY: 'CHEMISTRY',
    ELECTRIONCS: 'ELECTRIONCS',
    COMPUTER_SCIENCE: 'COMPUTER SCIENCE',
  },
  OTP: {
    ON_SCREEN: 'onscreen',
    SMS: 'sms',
    EMAIL: 'email',
    BOTH: 'both',
  },
  RESPONSE_STATUS: {
    SUCESS: 'sucess',
    SUCCESS: 'success',
  },
  COUNTRY_CODES: {
    DEFAULT: '+91',
  },
  ACADEMIC_YEAR: '2022',
  SELF: { YES: 'Yes', NO: 'No' },
  VALIDATIONS: {
    REQ: 'Required',
    STATUS: {
      ERROR: 'error',
      SUCCESS: 'success',
    },
    TYPE: {
      STATUS: 'status',
    },
  },
  COLORS: {
    SIDENAV: {
      STEP_COLOR: {
        RANGE: 230,
      },
    },
    HEADER: {
      AVATAR_COLOR: {
        RANGE: 230,
      },
    },
  },
  LAYOUTS: {
    DEFAULT: 'overlay',
    OVERLAY: 'overlay',
    CROPPED: 'cropped',
  },
};
